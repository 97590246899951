import { Flex, Link } from "@radix-ui/themes";
import React from "react";
import { env } from "../../env";

export const ContactUsLink: React.FC = () => (
  <Flex justify="center">
    <Link href={env.contactPage} target="_blank">
      Kontakt
    </Link>
  </Flex>
);
