import { Box } from "@chakra-ui/react";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { Callout, Code, Flex, Progress, Text } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { routes } from "../../routes";
import { captureCheckout } from "../../shared/api/nest-api";
import { ContactUsButton } from "../../shared/components/contact-us-button";
import { useCampingStation } from "../hooks/use-camping-station";
import { useCampingStationSlug } from "../hooks/use-camping-station-slug";
import { useResetCampingState } from "../hooks/use-reset-camping-state";
import { prefetchCampingBookingQuery } from "../queries/use-camping-booking-query";

let mutexCounter = 0;
const runWithMutex = async (cb: () => Promise<void>) => {
  try {
    if (mutexCounter++ !== 0) return;

    await cb();
  } finally {
    mutexCounter--;
  }
};

export const CampingRentCapturePage: React.FC = () => {
  const slug = useCampingStationSlug();
  const station = useCampingStation();
  const [searchParams] = useSearchParams();
  const checkoutId = searchParams.get("checkout_id");

  if (!checkoutId) {
    throw new Error("checkout_id query parameter required");
  }

  const navigate = useNavigate();
  const resetCampingState = useResetCampingState();

  const [error, setError] = useState<unknown>();

  useEffect(() => {
    // avoid capturing the same checkout multiple times,
    // e.g. when component is rendered multiple times in react strict mode
    void runWithMutex(async () => {
      try {
        const result = await captureCheckout(station.id, checkoutId);

        await prefetchCampingBookingQuery(result.bookingSlug);

        resetCampingState();

        void navigate(
          routes.camping.booking.success(slug, result.bookingSlug),
          {
            replace: true,
          },
        );
      } catch (error) {
        // display custom error message in this critical part of the app
        setError(error);
      }
    });
  }, [station.id, checkoutId, slug, navigate, resetCampingState]);

  if (error) {
    return (
      <Flex direction="column" gap="4">
        <Callout.Root color="red">
          <Callout.Icon>
            <ExclamationTriangleIcon />
          </Callout.Icon>
          <Callout.Text>
            Es ist leider ein Fehler bei der Buchung aufgetreten!
          </Callout.Text>
        </Callout.Root>

        <div>
          Bitte kontaktieren Sie uns damit wir Ihnen schnell und unkompliziert
          helfen können.
        </div>

        <div>
          Nennen Sie uns dabei bitte Ihre Buchungsnummer:{" "}
          <Code>{checkoutId.substring(0, 15)}</Code>.
        </div>

        <ContactUsButton />
      </Flex>
    );
  }

  return (
    <Flex direction="column" align="center" gap="4">
      <Text>
        Bitte haben Sie einen kurzen Moment Geduld während wir Ihre Zahlung
        verarbeiten.
      </Text>

      <Box width="100%">
        <Progress duration="10s" size="3" />
      </Box>
    </Flex>
  );
};
