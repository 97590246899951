import { Button, Flex, Text } from "@radix-ui/themes";
import { isAfter, isBefore, isSameDay, parse, set, sub } from "date-fns";
import { usePostHog } from "posthog-js/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { routes } from "../../../routes";
import { useCampingStation } from "../../hooks/use-camping-station";
import { useCampingStationSlug } from "../../hooks/use-camping-station-slug";
import { prefetchCampingQuoteQuery } from "../../queries/use-camping-quote-query";
import { selectedDurationState } from "../../state/selected-duration.state";
import { selectedPickupDateState } from "../../state/selected-pickup-date.state";
import { DaySelect } from "./day-select.component";
import { DurationSelect } from "./duration-select.component";
import { TimeSelect } from "./time-select.component";

const timeIntervalInMinutes = 15;

export const CampingRentPage: React.FC = () => {
  const slug = useCampingStationSlug();
  const campingStation = useCampingStation();
  const posthog = usePostHog();

  const [selectedPickupDate, setSelectedPickupDate] = useRecoilState(
    selectedPickupDateState(slug),
  );
  const [selectedDuration, setSelectedDuration] = useRecoilState(
    selectedDurationState(slug),
  );

  useEffect(() => {
    posthog.capture("selected_pickup_date", {
      value: selectedPickupDate?.toISOString(),
    });
  }, [posthog, selectedPickupDate]);
  useEffect(() => {
    posthog.capture("selected_duration", {
      value: selectedDuration,
    });
  }, [posthog, selectedDuration]);

  const now = useMemo(() => new Date(), []);
  const earliestPickupTime = useMemo(
    () => sub(new Date(now), { minutes: timeIntervalInMinutes }),
    [now],
  );
  const openHours = useMemo(
    () => ({
      start: campingStation.openingHours?.start
        ? parse(
            campingStation.openingHours.start,
            "HH:mm:ss",
            selectedPickupDate ?? new Date(),
          )
        : undefined,
      end: campingStation.openingHours?.end
        ? parse(
            campingStation.openingHours.end,
            "HH:mm:ss",
            selectedPickupDate ?? new Date(),
          )
        : undefined,
    }),
    [selectedPickupDate, campingStation.openingHours],
  );

  const handleDateSelection = useCallback(
    (date: Date) => {
      setSelectedPickupDate((previous) =>
        previous
          ? set(new Date(date), {
              hours: previous.getHours(),
              minutes: previous.getMinutes(),
            })
          : date,
      );
    },
    [setSelectedPickupDate],
  );

  const handleTimeSelection = useCallback(
    (time: Date) => {
      setSelectedPickupDate((previous) =>
        set(previous ? new Date(previous) : new Date(), {
          hours: time.getHours(),
          minutes: time.getMinutes(),
          seconds: time.getSeconds(),
          milliseconds: time.getMilliseconds(),
        }),
      );
    },
    [setSelectedPickupDate],
  );

  const navigate = useNavigate();
  const [navigating, setNavigating] = useState(false);
  const handleNextClick = useCallback(() => {
    if (!selectedPickupDate || !selectedDuration || navigating) return;

    setNavigating(true);

    void (async () => {
      await prefetchCampingQuoteQuery(campingStation.id, {
        pickupDate: selectedPickupDate,
        duration: selectedDuration,
      });

      void navigate(routes.camping.rent.bikeSelection(slug));
    })();
  }, [
    navigate,
    navigating,
    campingStation.id,
    selectedPickupDate,
    selectedDuration,
    slug,
  ]);

  const formValid =
    selectedPickupDate &&
    !isBefore(selectedPickupDate, earliestPickupTime) &&
    (!openHours.start || !isBefore(selectedPickupDate, openHours.start)) &&
    (!openHours.end || !isAfter(selectedPickupDate, openHours.end)) &&
    selectedDuration;

  return (
    <Flex gap="4" direction="column" align="center">
      <Flex direction="column" gap="4">
        <Text>
          Wählen Sie bitte einen Zeitraum aus, dann zeigen wir Ihnen im nächsten
          Schritt die verfügbaren Räder zur Auswahl an.
        </Text>

        <DaySelect
          value={selectedPickupDate}
          onChange={handleDateSelection}
          disabled={navigating}
        />

        <Flex direction={"column"} gap="1">
          <Text as="label" htmlFor="pickupTime">
            Wann möchten Sie das Rad abholen?
          </Text>

          <TimeSelect
            id="pickupTime"
            value={selectedPickupDate}
            onChange={handleTimeSelection}
            minTime={openHours.start}
            maxTime={openHours.end}
            minuteInterval={timeIntervalInMinutes}
            disableBefore={
              selectedPickupDate &&
              isSameDay(selectedPickupDate, earliestPickupTime) &&
              earliestPickupTime
            }
            disabled={navigating}
          />
        </Flex>

        <Flex direction="column" gap="1">
          <Text as="label" htmlFor="duration">
            Für wie lange möchten Sie es leihen?
          </Text>

          <DurationSelect
            id="duration"
            value={selectedDuration}
            onChange={setSelectedDuration}
            disabled={navigating}
          />
        </Flex>

        <Flex justify="end">
          <Button
            disabled={!formValid || navigating}
            onClick={handleNextClick}
            loading={navigating}
          >
            zur Rad-Auswahl
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
