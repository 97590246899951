import { Select } from "@radix-ui/themes";
import { useCallback } from "react";
import { BookingDuration, bookingDurationLabels } from "use-smart-locks-shared";

export const DurationSelect: React.FC<{
  id?: string;
  value: BookingDuration | undefined;
  onChange: (duration: BookingDuration | undefined) => void;
  disabled?: boolean;
}> = ({ id, value, onChange, disabled }) => {
  const handleValueChange = useCallback(
    (valueString: string) => {
      onChange(valueString as BookingDuration | undefined);
    },
    [onChange],
  );

  const selectedOption = value ?? "";

  return (
    <Select.Root
      value={selectedOption}
      onValueChange={handleValueChange}
      disabled={disabled}
    >
      <Select.Trigger placeholder="Zeitspanne auswählen" id={id} />
      <Select.Content position="popper">
        <Select.Group>
          <Select.Label>Zeitspanne</Select.Label>
          {Object.entries(bookingDurationLabels).map(([key, value]) => (
            <Select.Item key={key} value={key}>
              {/* Additional span to avoid that this crashes with Google Translate: https://github.com/radix-ui/primitives/issues/2578#issuecomment-1890801041 */}
              <span>{value}</span>
            </Select.Item>
          ))}
        </Select.Group>
      </Select.Content>
    </Select.Root>
  );
};
