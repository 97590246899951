import { Select } from "@radix-ui/themes";
import { isBefore, set } from "date-fns";
import { useCallback, useMemo } from "react";

type TimeOption = {
  hours: number;
  minutes: number;
};

const timeOptionId = (hours: number, minutes: number): string =>
  (hours * 60 + minutes).toString();

export const TimeSelect: React.FC<{
  id?: string;
  value: Date | undefined;
  onChange: (time: Date) => void;
  minTime?: Date;
  maxTime?: Date;
  minuteInterval: number;
  disableBefore?: Date | false;
  disabled?: boolean;
}> = ({
  id,
  value,
  onChange,
  minTime,
  maxTime,
  minuteInterval,
  disableBefore,
  disabled,
}) => {
  const isDisabled = useCallback(
    (option: TimeOption): boolean =>
      !!disableBefore &&
      isBefore(
        set(new Date(disableBefore), {
          hours: option.hours,
          minutes: option.minutes,
        }),
        disableBefore,
      ),
    [disableBefore],
  );

  const options: Record<string, TimeOption> = useMemo(() => {
    const items: Record<string, TimeOption> = {};

    for (let iHour = 0; iHour < 24; iHour++) {
      if (
        (!!minTime && iHour < minTime.getHours()) ||
        (!!maxTime && iHour > maxTime.getHours())
      ) {
        continue;
      }

      for (let iMinute = 0; iMinute < 60; iMinute += minuteInterval) {
        if (
          (!!minTime &&
            iHour === minTime.getHours() &&
            iMinute < minTime.getMinutes()) ||
          (!!maxTime &&
            iHour === maxTime.getHours() &&
            iMinute > maxTime.getMinutes())
        ) {
          continue;
        }

        items[timeOptionId(iHour, iMinute)] = {
          hours: iHour,
          minutes: iMinute,
        };
      }
    }

    return items;
  }, [minTime, maxTime, minuteInterval]);

  const handleValueChange = useCallback(
    (optionId: string) => {
      if (!(optionId in options)) return;

      const option = options[optionId];

      onChange(
        set(new Date(), {
          hours: option.hours,
          minutes: option.minutes,
          seconds: 0,
          milliseconds: 0,
        }),
      );
    },
    [onChange, options],
  );

  let selectedOption =
    value && timeOptionId(value.getHours(), value.getMinutes());
  if (
    !selectedOption ||
    !(selectedOption in options) ||
    isDisabled(options[selectedOption])
  ) {
    selectedOption = "";
  }

  return (
    <Select.Root
      value={selectedOption}
      onValueChange={handleValueChange}
      disabled={disabled}
    >
      <Select.Trigger placeholder="Uhrzeit auswählen" id={id} />
      <Select.Content position="popper">
        <Select.Group>
          <Select.Label>Uhrzeit</Select.Label>
          {Object.entries(options).map(([key, value]) => (
            <Select.Item key={key} value={key} disabled={isDisabled(value)}>
              {/* Additional span to avoid that this crashes with Google Translate: https://github.com/radix-ui/primitives/issues/2578#issuecomment-1890801041 */}
              <span>
                {value.hours.toString().padStart(2, "0")}:
                {value.minutes.toString().padEnd(2, "0")} Uhr
              </span>
            </Select.Item>
          ))}
        </Select.Group>
      </Select.Content>
    </Select.Root>
  );
};
