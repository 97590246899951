import { Card, Flex } from "@radix-ui/themes";
import { de } from "date-fns/locale";
import { DayPicker } from "react-day-picker";

export const DaySelect: React.FC<{
  value: Date | undefined;
  onChange: (date: Date) => void;
  disabled?: boolean;
}> = ({ value, onChange, disabled }) => {
  const now = new Date();

  return (
    <Card>
      <Flex justify="center">
        <DayPicker
          locale={de}
          mode="single"
          selected={value}
          onSelect={(date) => date && onChange(date)}
          fromDate={now}
          required
          disabled={disabled}
        />
      </Flex>
    </Card>
  );
};
