import { CheckCircledIcon, InfoCircledIcon } from "@radix-ui/react-icons";
import {
  Avatar,
  Blockquote,
  Box,
  Button,
  Callout,
  Card,
  Flex,
  Strong,
  Text,
} from "@radix-ui/themes";
import type React from "react";
import { useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import {
  BOOKING_UNLOCK_GRACE_PERIOD_HOURS,
  BOOKING_UNLOCK_THRESHOLD_MINUTES,
  type CampingUnit,
  formatDate,
  formatTime,
  formatTimeUntil,
  isAtMostNHoursAfter,
  isInFuture,
} from "use-smart-locks-shared";
import UnlockBikeIcon from "../../assets/unlock-bike-icon.svg?react";
import { getApiUserErrorMessage } from "../../shared/api/nest-api";
import { useUnlockLocksOfBookingMutation } from "../commands/use-unlock-locks-of-booking-mutation";
import { useCampingBooking } from "../hooks/use-camping-booking";
import { EndBooking } from "./camping-booking-unlock-page/end-booking.component";

export default function CampingBookingUnlockPage() {
  const booking = useCampingBooking();

  const multipleItems = booking.units.length > 1;

  const includesUnitLocks = booking.units.every((unit) => !unit.pin);
  const hasDoorLock = !booking.units.every((unit) => !unit.hasLock);

  const {
    mutate: unlock,
    isSuccess: isUnlockSuccess,
    status: unlockStatus,
  } = useUnlockLocksOfBookingMutation({
    onError(error) {
      toast.error(
        getApiUserErrorMessage(error) ??
          "Leider ist ein unerwarteter Fehler aufgetreten.",
      );
    },
  });

  const isUnlockAvailable = !isInFuture(
    booking.starts,
    BOOKING_UNLOCK_THRESHOLD_MINUTES,
  );
  const isUnlockLoading = unlockStatus === "pending";
  const isUnlockDisabled =
    !isUnlockAvailable || isUnlockLoading || !!booking.endedAt;
  const isExpired = !isAtMostNHoursAfter(
    booking.ends,
    BOOKING_UNLOCK_GRACE_PERIOD_HOURS,
  );

  const [, rerender] = useReducer((s) => !s, false);

  useEffect(() => {
    if (isUnlockAvailable) return;
    // TODO: is once a second really necessary? maybe speed it up once we reach 1min?
    const interval = setInterval(rerender, 1000);

    return () => clearInterval(interval);
  }, [isUnlockAvailable]);

  if (isExpired)
    return (
      <Flex direction="column" gap="4" minWidth="100%">
        <Callout.Root>
          <Callout.Icon>
            <InfoCircledIcon />
          </Callout.Icon>
          <Callout.Text>
            Ihre Buchung ist abgelaufen. Vielen Dank für die Nutzung unseres
            Services!
          </Callout.Text>
        </Callout.Root>
      </Flex>
    );

  return (
    <Flex direction="column" gap="4" minWidth="100%">
      <Flex justify="center">
        <UnlockBikeIcon className="unlock-bike-icon" aria-hidden />
      </Flex>

      <Text>
        Sie können Ihr{multipleItems ? "e Fahrräder" : " Fahrrad"}{" "}
        <Strong>
          {formatTimeUntil(booking.starts, BOOKING_UNLOCK_THRESHOLD_MINUTES)}
        </Strong>{" "}
        in der <Strong>{booking.pickupLocation}</Strong> abholen.
      </Text>

      <Blockquote>
        am {formatDate(booking.starts)}, von {formatTime(booking.starts)} bis{" "}
        {formatTime(booking.ends)}
      </Blockquote>

      {(hasDoorLock || includesUnitLocks) && (
        <Text>
          Elektronisch gesicherte Fahrräder und Türen können durch Klicken auf
          den unten stehenden Knopf geöffnet werden.
        </Text>
      )}

      {booking.units.some((unit) => unit.pin) && (
        <Text>
          Fahrräder mit Zahlenschloss können mit der PIN aufgeschlossen werden.
        </Text>
      )}

      {booking.units.map((unit) => (
        <UnitCard
          key={unit.id}
          unit={unit}
          asideRight={
            <Flex direction="column" gap="0">
              {unit.pickupHint && <Text size="1">{unit.pickupHint}</Text>}
              {unit.pin && (
                <Text>PIN: {isUnlockAvailable ? unit.pin : "****"}</Text>
              )}
            </Flex>
          }
        />
      ))}

      {booking.units.some((unit) => unit.hasLock) && (
        <Button
          disabled={isUnlockDisabled}
          onClick={() => unlock(booking.id)}
          loading={isUnlockLoading}
        >
          Eingangstür {includesUnitLocks ? "und Schlüsselkästen" : ""} öffnen
        </Button>
      )}
      {isUnlockSuccess && (
        <Callout.Root color="green">
          <Callout.Icon>
            <CheckCircledIcon />
          </Callout.Icon>
          <Callout.Text>
            Eingangstür {includesUnitLocks ? "und Schlüsselkästen" : ""}{" "}
            geöffnet, bitte nehmen Sie Ihr
            {multipleItems ? "e Fahrräder" : " Fahrrad"}.
          </Callout.Text>
        </Callout.Root>
      )}

      <EndBooking booking={booking} />
    </Flex>
  );
}

function UnitCard({
  unit,
  asideRight,
}: {
  unit: CampingUnit;
  asideRight?: React.ReactNode;
}) {
  return (
    <Card size="1">
      <Flex gap="3" align="center">
        <Avatar
          className="unit"
          size="3"
          src={unit.imageUrl}
          radius="large"
          fallback={unit.name.charAt(0)}
        />
        <Box flexGrow="1">
          <Text as="div" size="2" weight="bold">
            {unit.name}
          </Text>
          <Text as="div" size="2" color="gray">
            {unit.description}
          </Text>
        </Box>

        {asideRight && <Box width="80px">{asideRight}</Box>}
      </Flex>
    </Card>
  );
}
